<template>
  <div class="app-auth-sign-in">
    <div class="app-auth-container">
      <div class="auth-header">
        <img
          src="/images/vars_logo.png"
          alt="VARS Technology"
          class="auth-logo"
        />
        <h1>Forgot Password</h1>
      </div>
      <p class="auth-description">
        Please enter the email address associated with your account and we will
        send you a reset link.
      </p>
      <div v-if="!complete" class="auth-credentials">
        <div class="form-group">
          <label for="signInEmail" class="form-label">Email Address</label>
          <input
            v-model="email"
            type="email"
            class="form-control"
            id="signInEmail"
            aria-describedby="signInEmail"
            placeholder="example@varsanpr.com"
            name="username"
          />
        </div>
        <p v-show="message" class="text-danger">{{ message }}</p>
        <div class="auth-actions">
          <button
            type="submit"
            @click="sendReset"
            :disabled="loading || email.length < 3"
            class="btn btn-primary"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Send me a Link</span>
          </button>
          <a href="/login" class="auth-link">Back to Login</a>
        </div>
      </div>
      <div v-else>
        <p class="auth-description">
          If the email address is correct, we will send you a link to your
          email. Please check your inbox and follow the instructions to reset
          your password.
        </p>
        <button class="btn btn-primary" @click="$router.push('/login')">
          Back to Login
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Forgot",
  data() {
    return {
      loading: false,
      message: "",
      complete: false,
      email: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    night() {
      return this.$store.state.auth.status.dark;
    },
  },
  methods: {
    sendReset() {
      axios
        .post(`https://api.varsanpr.com/api/auth/reset`, {
          email: this.email,
        })
        .then((response) => {
          this.complete = true;
        })
        .catch((error) => {
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message =
              "There was an issue with your request. Please try again later.";
          }
        });
    },
  },
};
</script>

<style scoped>
.app-auth-sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.app-auth-container {
  max-width: 600px;
  max-height: max-content;
  margin: 0 auto;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .app-auth-container {
    width: 100%;
    padding: 20px;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

.auth-header {
  text-align: center;
  margin-bottom: 20px;
}

.auth-logo {
  height: 50px;
  margin-bottom: 10px;
}

.auth-description {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.btn {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.auth-actions {
  text-align: center;
}

.auth-link {
  display: block;
  margin-top: 10px;
  color: #007bff;
}

.auth-link:hover {
  text-decoration: underline;
}

.error-feedback {
  color: #ff5e5e;
  font-size: 12px;
  margin-top: 5px;
}
</style>
